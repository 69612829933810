export default function usePriceFormatter() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const { locale } = useI18n();

  /********************
   * FUNCTIONS         *
   ********************/
  function getFormattedPrice(price: number) {
    // Catch potential errors on mobile Safari 9
    try {
      return price.toLocaleString(locale.value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } catch (e) {
      return price.toString();
    }
  }

  function getDecimalSeparator(price: number) {
    return getFormattedPrice(price).replace(/\d/g, '');
  }

  return {
    getFormattedPrice,
    getDecimalSeparator,
  };
}
