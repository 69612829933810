<script setup lang="ts">
// icons
import { faHeart as fasHeart } from '@fortawesome/pro-solid-svg-icons';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';

import CiAwesomeIcon from '@/components/common/CiAwesomeIcon/CiAwesomeIcon.vue';

export interface CiCampsiteFavoriteProps {
  active: boolean;
  iconRatio?: string;
  isCpdp?: boolean;
  showLabel?: boolean;
}
const props = withDefaults(defineProps<CiCampsiteFavoriteProps>(), {
  iconRatio: '1',
  isCpdp: false,
  showLabel: true,
});

/********************
 * COMPOSITIONS      *
 ********************/
const { $gettext } = useGettext();

/********************
 * REFS & VARS       *
 ********************/
const iconRef = ref<typeof CiAwesomeIcon | null>(null);
const iconClasses = ref(['fill-danger', props.isCpdp ? 'mb-2 md:mb-0 md:mr-4' : '']);
const model = ref(props.active);

const favIcon = computed(() => {
  return model.value ? fasHeart : faHeart;
});

/********************
 * WATCHERS          *
 ********************/
watch(() => props.active, onActiveChange, { immediate: false });

/********************
 * METHODS           *
 ********************/
function onActiveChange(newValue: boolean) {
  model.value = newValue;
  iconRef.value?.$el?.classList?.add(newValue ? 'animate-[heartbeat_1s_ease]' : 'animate-[fade-out_1s_ease]');

  setTimeout(() => {
    iconRef.value?.$el?.classList?.remove('animate-[heartbeat_1s_ease]');
    iconRef.value?.$el?.classList?.remove('animate-[fade-out_1s_ease]');
  }, 1000);
}
</script>

<template>
  <div
    class="fav-button group cursor-pointer text-center"
    data-cy="ci-campsite-favorite"
    :data-favorite-status="`${props.active}`"
  >
    <client-only>
      <div
        class="flex items-center justify-center font-medium"
        :class="{ 'text-tiny-book flex-col text-dark md:flex-row': props.isCpdp }"
      >
        <CiAwesomeIcon
          ref="iconRef"
          :icon="favIcon"
          class="fav-icon transition-transform duration-200 ease-in-out group-hover:scale-125"
          :class="iconClasses"
          :ratio="props.iconRatio"
        />
        <span
          v-if="props.showLabel"
          key="active"
          class="uppercase"
          :data-cy="props.active ? 'ci-campsite-favorite--active' : 'ci-campsite-favorite--inactive'"
        >
          <span>{{ $gettext('Favorite') }}</span>
        </span>
      </div>
    </client-only>
  </div>
</template>

<style></style>
